import { createSlice } from "@reduxjs/toolkit";
import {
  getOwnerProperties,
  addOwnerProperty,
  getAllPropertyListingType,
  getAllPropertyRoomType,
  getAllCountries,
  uploadPropertyImages,
  getPropertyImages,
  getSpecificProperty,
  updatePropertyStatus,
  updatePropertyOwner,
  updatePropertyDetails,
  updatePropertyPrices,
  getPropertySpecificationsColumns,
  updatePropertySpecifications,
  updatePropertyImages,
  blockPropertyReservation,
  addPropertyExpense,
} from "../../thunks/propertyThunk";

interface PropertySlice {
  propertyLoading: Boolean;
  allCountries: any[] | null;
  allPropertyListingTypes: any[] | null;
  allPropertyRoomTypes: any[] | null;
  error: any;
  response: any;
  ownerProperties: Array<Object>;
  selectedReservePropertyDetails: any;
  newProperty: Object;
  addPropertyLoading: Boolean;
  imageUpload: any;
  propertySpecificationCulumn: any;

  specificPropertyLoading: Boolean;
  updatePropertyStatusLoading: Boolean;
  updatePropertyOwnerLoading: Boolean;
  updatePropertyDetailsLoading: Boolean;
  updatePropertyPricesLoading: Boolean;
  getPropertySpecificationsColumnsLoading: Boolean;
  getAllPropertyListingLoading: Boolean;
  getAllPropertyRoomTypeLoading: Boolean;
  getAllCountriesLoading: Boolean;
  getPropertyImagesLoading: Boolean;
  updatePropertySpecificationsLoading: Boolean;
  updatePropertyImagesLoading: Boolean;
  blockPropertyReservationLoading: Boolean;
  addPropertyExpenseLoading: Boolean;
}

const initialState: PropertySlice = {
  propertyLoading: false,
  error: null,
  response: null,
  ownerProperties: [],
  newProperty: {},
  allPropertyListingTypes: [],
  allPropertyRoomTypes: [],
  allCountries: [],
  addPropertyLoading: false,
  imageUpload: null,
  selectedReservePropertyDetails: null,
  propertySpecificationCulumn: null,

  specificPropertyLoading: false,
  updatePropertyStatusLoading: false,
  updatePropertyOwnerLoading: false,
  updatePropertyDetailsLoading: false,
  updatePropertyPricesLoading: false,
  getPropertySpecificationsColumnsLoading: false,
  getAllPropertyListingLoading: false,
  getAllPropertyRoomTypeLoading: false,
  getAllCountriesLoading: false,
  getPropertyImagesLoading: false,
  updatePropertySpecificationsLoading: false,
  updatePropertyImagesLoading: false,
  blockPropertyReservationLoading: false,
  addPropertyExpenseLoading: false,
};

const propertySlice = createSlice({
  name: "property",
  initialState,
  reducers: {
    resetProperty: (state) => {},
  },
  extraReducers(builder) {
    builder.addCase(getOwnerProperties.pending, (state) => {
      state.propertyLoading = true;
      state.error = null;
      state.response = null;
    });
    builder.addCase(getOwnerProperties.fulfilled, (state, action) => {
      state.propertyLoading = false;
      state.ownerProperties = action.payload.owner_properties;
    });
    builder.addCase(getOwnerProperties.rejected, (state, action) => {
      state.propertyLoading = false;
      state.error = action.payload;
    });

    builder.addCase(addOwnerProperty.pending, (state) => {
      state.addPropertyLoading = true;
      state.error = null;
      state.response = null;
    });
    builder.addCase(addOwnerProperty.fulfilled, (state, action) => {
      state.addPropertyLoading = false;
      state.newProperty = action.payload;
    });
    builder.addCase(addOwnerProperty.rejected, (state, action) => {
      state.addPropertyLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getAllPropertyListingType.pending, (state) => {
      state.getAllPropertyListingLoading = true;
      state.error = null;
    });
    builder.addCase(getAllPropertyListingType.fulfilled, (state, action) => {
      state.getAllPropertyListingLoading = true;
      state.allPropertyListingTypes = action.payload.all_property_listing_types;
    });
    builder.addCase(getAllPropertyListingType.rejected, (state, action) => {
      state.getAllPropertyListingLoading = true;
      state.error = action.payload;
    });

    builder.addCase(getAllPropertyRoomType.pending, (state) => {
      state.getAllPropertyRoomTypeLoading = true;
      state.error = null;
    });
    builder.addCase(getAllPropertyRoomType.fulfilled, (state, action) => {
      state.getAllPropertyRoomTypeLoading = true;
      state.allPropertyRoomTypes = action.payload.all_property_room_types;
    });
    builder.addCase(getAllPropertyRoomType.rejected, (state, action) => {
      state.getAllPropertyRoomTypeLoading = true;
      state.error = action.payload;
    });

    builder.addCase(getAllCountries.pending, (state) => {
      state.getAllCountriesLoading = true;
      state.error = null;
    });
    builder.addCase(getAllCountries.fulfilled, (state, action) => {
      state.getAllCountriesLoading = true;
      state.allCountries = action.payload.countries;
    });
    builder.addCase(getAllCountries.rejected, (state, action) => {
      state.getAllCountriesLoading = true;
      state.error = action.payload;
    });

    builder.addCase(uploadPropertyImages.pending, (state) => {
      state.addPropertyLoading = true;
      state.error = null;
      state.response = null;
    });
    builder.addCase(uploadPropertyImages.fulfilled, (state, action) => {
      state.addPropertyLoading = false;
      state.imageUpload = action.payload;
    });
    builder.addCase(uploadPropertyImages.rejected, (state, action) => {
      state.addPropertyLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getPropertyImages.pending, (state) => {
      state.getPropertyImagesLoading = true;
      state.error = null;
      state.response = null;
    });
    builder.addCase(getPropertyImages.fulfilled, (state, action) => {
      state.getPropertyImagesLoading = false;
      state.ownerProperties = action.payload.owner_properties;
    });
    builder.addCase(getPropertyImages.rejected, (state, action) => {
      state.getPropertyImagesLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getSpecificProperty.pending, (state) => {
      state.specificPropertyLoading = true;
      state.error = null;
      state.response = null;
    });
    builder.addCase(getSpecificProperty.fulfilled, (state, action) => {
      state.specificPropertyLoading = false;
      state.selectedReservePropertyDetails = action.payload.property_details;
    });
    builder.addCase(getSpecificProperty.rejected, (state, action) => {
      state.specificPropertyLoading = false;
      state.error = action.payload;
    });

    builder.addCase(updatePropertyStatus.pending, (state) => {
      state.updatePropertyStatusLoading = true;
      state.error = null;
      state.response = null;
    });
    builder.addCase(updatePropertyStatus.fulfilled, (state, action) => {
      state.updatePropertyStatusLoading = false;
      state.response = action.payload;
    });
    builder.addCase(updatePropertyStatus.rejected, (state, action) => {
      state.updatePropertyStatusLoading = false;
      state.error = action.payload;
    });

    builder.addCase(updatePropertyOwner.pending, (state) => {
      state.updatePropertyOwnerLoading = true;
      state.error = null;
      state.response = null;
    });
    builder.addCase(updatePropertyOwner.fulfilled, (state, action) => {
      state.updatePropertyOwnerLoading = false;
      state.response = action.payload;
    });
    builder.addCase(updatePropertyOwner.rejected, (state, action) => {
      state.updatePropertyOwnerLoading = false;
      state.error = action.payload;
    });

    builder.addCase(updatePropertyDetails.pending, (state) => {
      state.updatePropertyDetailsLoading = true;
      state.error = null;
      state.response = null;
    });
    builder.addCase(updatePropertyDetails.fulfilled, (state, action) => {
      state.updatePropertyDetailsLoading = false;
      state.response = action.payload;
    });
    builder.addCase(updatePropertyDetails.rejected, (state, action) => {
      state.updatePropertyDetailsLoading = false;
      state.error = action.payload;
    });

    builder.addCase(updatePropertyPrices.pending, (state) => {
      state.updatePropertyPricesLoading = true;
      state.error = null;
      state.response = null;
    });
    builder.addCase(updatePropertyPrices.fulfilled, (state, action) => {
      state.updatePropertyPricesLoading = false;
      state.response = action.payload;
    });
    builder.addCase(updatePropertyPrices.rejected, (state, action) => {
      state.updatePropertyPricesLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getPropertySpecificationsColumns.pending, (state) => {
      state.getPropertySpecificationsColumnsLoading = true;
      state.error = null;
      state.response = null;
    });
    builder.addCase(
      getPropertySpecificationsColumns.fulfilled,
      (state, action) => {
        state.getPropertySpecificationsColumnsLoading = false;
        state.propertySpecificationCulumn =
          action.payload.property_specifications_column;
      }
    );
    builder.addCase(
      getPropertySpecificationsColumns.rejected,
      (state, action) => {
        state.getPropertySpecificationsColumnsLoading = false;
        state.error = action.payload;
      }
    );

    builder.addCase(updatePropertySpecifications.pending, (state) => {
      state.updatePropertySpecificationsLoading = true;
      state.error = null;
      state.response = null;
    });
    builder.addCase(updatePropertySpecifications.fulfilled, (state, action) => {
      state.updatePropertySpecificationsLoading = false;
      state.response = action.payload;
    });
    builder.addCase(updatePropertySpecifications.rejected, (state, action) => {
      state.updatePropertySpecificationsLoading = false;
      state.error = action.payload;
    });

    builder.addCase(updatePropertyImages.pending, (state) => {
      state.updatePropertyImagesLoading = true;
      state.error = null;
      state.response = null;
    });
    builder.addCase(updatePropertyImages.fulfilled, (state, action) => {
      state.updatePropertyImagesLoading = false;
      state.response = action.payload;
    });
    builder.addCase(updatePropertyImages.rejected, (state, action) => {
      state.updatePropertyImagesLoading = false;
      state.error = action.payload;
    });

    builder.addCase(blockPropertyReservation.pending, (state) => {
      state.blockPropertyReservationLoading = true;
      state.error = null;
      state.response = null;
    });
    builder.addCase(blockPropertyReservation.fulfilled, (state, action) => {
      state.blockPropertyReservationLoading = false;
      state.response = action.payload;
    });
    builder.addCase(blockPropertyReservation.rejected, (state, action) => {
      state.blockPropertyReservationLoading = false;
      state.error = action.payload;
    });

    builder.addCase(addPropertyExpense.pending, (state) => {
      state.addPropertyExpenseLoading = true;
      state.error = null;
      state.response = null;
    });
    builder.addCase(addPropertyExpense.fulfilled, (state, action) => {
      state.addPropertyExpenseLoading = false;
      state.response = action.payload;
    });
    builder.addCase(addPropertyExpense.rejected, (state, action) => {
      state.addPropertyExpenseLoading = false;
      state.error = action.payload;
    });
  },
});

export const { resetProperty } = propertySlice.actions;

export const propertyReducer = propertySlice.reducer;
