import React, { useContext, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { useNavigate } from "react-router-dom";
import { Autocomplete, Box, Grid, TextField, Typography } from "@mui/material";

import AccountDrawer from "../../components/commonComponents/accountDrawer/accountDrawer";
import OverviewCard from "./OverviewCard";
import GrossProfit from "../../assets/icons/grossProfit.png";
import Expenses from "../../assets/icons/expenses.png";
import NetProfit from "../../assets/icons/netProfit.png";
import RevenueChart from "./RevenueChart";
import ProgressChart from "./ProgressChart";
import GuestListChart from "./GuestListChart";
import BookStatCard from "./BookStatPieChart";
import OccupancyRatesPieChart from "./OccupancyRatesPieChart";
import {
  getPropertyMonthlyExpense,
  getPropertyMonthlyIncome,
} from "../../store/thunks/systemThunk";
import { getOwnerProperties } from "../../store/thunks/propertyThunk";
import Spinner from "../../components/reusableComponents/spinner/spinner";
import { displayConsoleError } from "../../utilities/helperFunctions";

const Reports = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { userInfo, loggedIn } = useAppSelector(
    (state: any) => state.authentication
  );
  const [selectedPropertyReport, setSelectedPropertyReport] =
    useState<any>(null);

  const { ownerProperties, propertyLoading } = useAppSelector(
    (state: any) => state.property
  );

  const [grossProfit, setGrossProfit] = useState(0);
  const [expenses, setExpenses] = useState(0);
  const [netProfit, setNetProfit] = useState(0);

  const [adR, setAdR] = useState(0);
  const [occupancyRate, setOccupancyRate] = useState(0);
  const [cancellationRate, setCancellationRate] = useState(0);
  const [occupancyRateCancellationRate, setOccupancyRateCancellationRate] =
    useState<any[]>([]);
  const [bookingsBySource, setBookingsBySource] = useState<any[]>([]);
  const [bookings, setBookings] = useState([]);
  useEffect(() => {
    if (!loggedIn) {
      navigate("/login");
    } else {
      dispatch(
        getOwnerProperties({
          user_id: userInfo?.user_id,
          account_id: userInfo?.account_id,
        })
      );
    }
  }, [navigate, loggedIn]);

  const handleDrawer = () => {
    setOpen(!open);
  };

  const handleSelectedPropertyReport = (selectedPropertyReport: any) => {
    setSelectedPropertyReport(selectedPropertyReport);
    setGrossProfit(0);
    setExpenses(0);
    setNetProfit(0);
    if (selectedPropertyReport) {
      dispatch(
        getPropertyMonthlyIncome({
          property_id: selectedPropertyReport?.property_id,
        })
      )
        .then((getPropertyMonthlyIncomeResult) => {
          if (getPropertyMonthlyIncomeResult.payload?.status === true) {
            setGrossProfit(
              getPropertyMonthlyIncomeResult.payload?.gross_monthly_income
            );
            setOccupancyRate(
              Math.round(
                (getPropertyMonthlyIncomeResult.payload?.booked_days /
                  getPropertyMonthlyIncomeResult.payload
                    ?.days_in_current_month) *
                  100
              )
            );
            setOccupancyRateCancellationRate([
              {
                value: Math.round(
                  (getPropertyMonthlyIncomeResult.payload?.booked_days /
                    getPropertyMonthlyIncomeResult.payload
                      ?.days_in_current_month) *
                    100
                ),
                label: "Occupancy Rate",
              },
              {
                value: 0,
                label: "Cancellation Rate",
              },
              {
                value:
                  getPropertyMonthlyIncomeResult.payload
                    ?.days_in_current_month -
                  getPropertyMonthlyIncomeResult.payload?.booked_days,
                label: "Open",
              },
            ]);
            setBookings(
              getPropertyMonthlyIncomeResult.payload?.monthly_bookings
            );

            const groupedBookings =
              getPropertyMonthlyIncomeResult.payload?.monthly_bookings?.reduce(
                (acc: any, booking: any) => {
                  const source = booking.booking_location_label;
                  if (!acc[source]) {
                    acc[source] = [];
                  }
                  acc[source].push(booking);
                  return acc;
                },
                {}
              );

            const bookingsBySource = Object.entries(groupedBookings).map(
              ([key, value]: [string, any], index: number) => {
                return {
                  value: value?.length,
                  label: key,
                  id: index,
                };
              }
            );

            setBookingsBySource(bookingsBySource);

            dispatch(
              getPropertyMonthlyExpense({
                property_id: selectedPropertyReport?.property_id,
              })
            )
              .then((getPropertyMonthlyExpenseResult) => {
                if (getPropertyMonthlyExpenseResult.payload?.status === true) {
                  let totalExpenses =
                    Number(getPropertyMonthlyIncomeResult.payload?.taxes) +
                    Number(getPropertyMonthlyIncomeResult.payload?.fees) +
                    Number(
                      getPropertyMonthlyExpenseResult.payload
                        ?.gross_monthly_expense
                    );
                  let netProfit =
                    Number(
                      getPropertyMonthlyIncomeResult.payload
                        ?.gross_monthly_income
                    ) - totalExpenses;
                  setExpenses(Math.round(totalExpenses * 100) / 100);
                  setNetProfit(Math.round(netProfit * 100) / 100);
                } else {
                  let totalExpenses =
                    Number(getPropertyMonthlyIncomeResult.payload?.taxes) +
                    Number(getPropertyMonthlyIncomeResult.payload?.fees);
                  let netProfit =
                    Number(
                      getPropertyMonthlyIncomeResult.payload
                        ?.gross_monthly_income
                    ) - totalExpenses;
                  setExpenses(Math.round(totalExpenses * 100) / 100);
                  setNetProfit(Math.round(netProfit * 100) / 100);
                }
              })
              .catch((getPropertyMonthlyExpenseError) => {
                displayConsoleError(
                  "getPropertyMonthlyExpenseError",
                  getPropertyMonthlyExpenseError
                );
              });
          }
        })
        .catch((getPropertyMonthlyIncomeError) => {
          displayConsoleError(
            "getPropertyMonthlyIncomeError",
            getPropertyMonthlyIncomeError
          );
        });
    }
  };

  return (
    <Box sx={{ margin: "0 15px 0 80px" }}>
      <AccountDrawer handleDrawer={handleDrawer} open={open} />
      <Spinner loading={propertyLoading} />
      <Grid container>
        <Grid item xs={12} sx={{ padding: "1%" }}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: "bold",
              textTransform: "capitalize",
            }}
          >
            Reports
          </Typography>
        </Grid>
      </Grid>
      <Grid container sx={{ padding: "1%" }}>
        <Autocomplete
          options={ownerProperties ? ownerProperties : []}
          onChange={(_, value: any) => handleSelectedPropertyReport(value)}
          value={selectedPropertyReport}
          sx={{ width: "100%" }}
          isOptionEqualToValue={(option, value) =>
            option?.property_id === value?.property_id
          }
          getOptionLabel={(option) => option.property_name}
          renderInput={(params) => (
            <TextField
              {...params}
              label={"Select Property"}
              id="select_property"
              name="select_property"
            />
          )}
        />
      </Grid>
      {selectedPropertyReport && (
        <Grid container sx={{ padding: "1%", gap: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Overview
              </Typography>
            </Grid>
            <OverviewCard
              title="Gross Profit"
              icon={GrossProfit}
              profit={grossProfit}
            />
            <OverviewCard title="Expenses" icon={Expenses} profit={expenses} />
            <OverviewCard
              title="Net Profit"
              icon={NetProfit}
              profit={netProfit}
            />
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Revenue
              </Typography>
              <RevenueChart />
            </Grid>
            <Grid item xs={12} md={4}>
              <ProgressChart
                title="ADR"
                data={selectedPropertyReport?.price_per_day}
                value={selectedPropertyReport?.price_per_day}
              />
              <ProgressChart
                title="Occupancy Rate"
                data={`${occupancyRate} %`}
                value={Number(occupancyRate)}
              />
              <ProgressChart
                title="Cancellation Rate"
                data={cancellationRate.toString()}
                value={Number(cancellationRate)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <GuestListChart bookings={bookings ? bookings : []} />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ marginBottom: "1%" }}>
            <Grid item xs={12} md={6}>
              <BookStatCard
                title={"Booking Statistics"}
                data={bookingsBySource}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <OccupancyRatesPieChart
                title={"Occupancy Rates"}
                data={occupancyRateCancellationRate}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default Reports;
